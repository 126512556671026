<template>
  <div class="subnav">
    <div class="subnav__row">
      <div @click="$router.push({ name: routeName })" v-if="backButton && routeName && mainLabel" class="subnav__icon">
        <img src="@/assets/icons/arrow-left-black.svg" />
      </div>
      <h2>{{ mainLabel }}</h2>
    </div>
    <Tabs :tabs="tabs" />
  </div>
  <div class="subroute">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Home',
  props: {
    mainLabel: {
      type: String
    },
    tabs: {
      type: Array
    },
    backButton: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String
    }
  },
  components: {
    Tabs
  },
  setup() {
    const store = useStore()
    const studioName = computed(() => (store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : ''))
    return { studioName }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.subnav
  background: $white
  padding: 0 3.75rem
  &__row
    display: flex
    align-items: center
  &__icon
    background-color: map-get($greyShades, 'greyShade10')
    display: flex
    align-items: center
    justify-content: center
    height: 2rem
    width: 2rem
    border-radius: 100%
    margin-right: 1rem
    cursor: pointer
    @media( max-width: $md-breakpoint)
      display: none
    img
      height: 12px
  h2
    margin: 1rem 0
  @media( max-width: $md-breakpoint)
    padding: 0
    h2
      display: none
</style>
