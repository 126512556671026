<template>
  <BoardLayout
    :backButton="true"
    :routeName="studioId ? 'AdminProjects' : 'Projects'"
    :mainLabel="projectDetails ? projectDetails.name : ''"
    :tabs="tabs"
  ></BoardLayout>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import BoardLayout from '@/components/templates/BoardLayout.vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { ActionTypes as projectActions } from '@/store/projects/actions'
export default defineComponent({
  components: {
    BoardLayout
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const studioId = route.params.studioId
    store.dispatch(projectActions.GET_PROJECT_DETAILS, +route.params.id)
    const projectDetails = computed(() => store.state.project.projectDetails)
    const tabs = []
    if (!studioId) {
      tabs.push(
        { routerName: 'ProjectDashboard', routerText: 'Dashboard' },
        { routerName: 'ProjectDetail', routerText: 'Details' },
        { routerName: 'ProjectTeam', routerText: 'Team' },
        { routerName: 'ProjectInvoicing', routerText: 'Invoicing' },
        { routerName: 'ProjectExpenses', routerText: 'Expenses' },
        { routerName: 'ProjectDocuments', routerText: 'Documents' },
        { routerName: 'ProjectTimesheets', routerText: 'Timesheets' }
      )
    } else {
      tabs.push({ routerName: 'ProjectDetailAdmin', routerText: 'Details' })
    }
    return { tabs, projectDetails, studioId }
  }
})
</script>
