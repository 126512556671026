
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Home',
  props: {
    mainLabel: {
      type: String
    },
    tabs: {
      type: Array
    },
    backButton: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String
    }
  },
  components: {
    Tabs
  },
  setup() {
    const store = useStore()
    const studioName = computed(() => (store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : ''))
    return { studioName }
  }
})
